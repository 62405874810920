<template>
<div>
  <div class="row row-small">
    <div class="col-24 col-lg-8">
      <v-select :label="label('salutation')" v-model="contact.salutation" attach :items="salutationItems" clearable :error="fieldError('salutation')" filled outlined hide-details="auto"></v-select>
    </div>

    <div class="col-24 col-lg-8">
      <v-text-field :label="label('firstname')" v-model.trim="contact.firstname" :error="fieldError('firstname')" outlined filled hide-details="auto"></v-text-field>
    </div>

    <div class="col-24 col-lg-8">
      <v-text-field :label="label('lastname')" v-model.trim="contact.lastname" :error="fieldError('lastname')" outlined filled hide-details="auto"></v-text-field>
    </div>
  </div>

  <div class="row row-small" v-if="enableAddress">
    <div class="col-24 col-lg-11">
      <v-text-field :label="label('street')" v-model="contact.street" :error="fieldError('street')" filled outlined hide-details="auto"></v-text-field>
    </div>

    <div class="col-24 col-lg-13">
        <div class="row row-small">
          <div class="col-24 col-lg-12">
            <v-text-field :label="label('zip')" v-model.trim="contact.zip" :error="fieldError('zip')" outlined filled hide-details="auto"></v-text-field>
          </div>

          <div class="col-24 col-lg-12">
            <v-text-field :label="label('city')" v-model.trim="contact.city" :error="fieldError('city')" outlined filled hide-details="auto"></v-text-field>
          </div>
        </div>
    </div>
  </div>

  <div class="row row-small">
    <div class="col-24 col-lg-12">
      <v-text-field :label="label('phone')" v-model.trim="contact.phone" :error="fieldError('phone')" outlined filled hide-details="auto"></v-text-field>
    </div>

    <div class="col-24 col-lg-12">
      <v-text-field :label="label('email')" v-model.trim="contact.email" :error="fieldError('email')" outlined filled hide-details="auto"></v-text-field>
    </div>
  </div>

  <div v-if="enableBirthday">
    <v-text-field :label="label('birthday')" v-model.trim="contact.birthday" :error="fieldError('birthday')" outlined filled hide-details="auto"></v-text-field>
  </div>

  <div v-if="enableMessage">
    <v-textarea rows="5" :label="label('message')" :error="fieldError('message')" v-model.trim="contact.message" outlined filled hide-details="auto"></v-textarea>
  </div>

  <v-checkbox hide-details v-model="dataPrivacy" :error="fieldError('dataPrivacy')">
    <template v-slot:label>
      <div class="text-xsmall" v-html="$t('dataPrivacy', { link: privacyUrlLink })"></div>
    </template>
  </v-checkbox>
</div>
</template>

<script>
export default {
  name: "ContactForm",

  props: {
    privacyUrl: {
      type: String,
      default: null
    },
    requiredFields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    enableMessage: {
      type: Boolean,
      default: false
    },
    enableBirthday: {
      type: Boolean,
      default: false
    },
    enableAddress: {
      type: Boolean,
      default: false
    },
    defaultMessage: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      salutationItems: [
        { text: "Frau", value: "Frau" },
        { text: "Herr", value: "Herr" },
        { text: "Divers", value: "Divers" }
      ],
      busy: false,
      contact: {
        salutation: null,
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        message: null
      },
      dataPrivacy: false
    };
  },

  computed: {
    privacyUrlLink() {
      return "<a href=\"" + this.privacyUrl + "\" target=\"_blank\" onclick=\"event.stopPropagation()\">" + this.$t("Datenschutzerklärung") + "</a>";
    },

    contactForm() {
      return this;
    }
  },

  methods: {
    validate() {
      this.busy = true;

      let valid = this.dataPrivacy;

      this.requiredFields.forEach(name => {
        if (!this.fieldValid(name)) {
          valid = false;
        }
      });

      return valid;
    },

    label(field) {
      let text = ((field) => {
        switch (field) {
          case "salutation":
            return this.$t("Anrede");

          case "firstname":
            return this.$t("Vorname");

          case "lastname":
            return this.$t("Nachname");

          case "birthday":
            return this.$t("Geburtsdatum");

          case "phone":
            return this.$t("Telefon");

          case "email":
            return this.$t("E-Mail");

          case "street":
            return this.$t("Straße");

          case "number":
            return this.$t("Hausnummer");

          case "zip":
            return this.$t("PLZ");

          case "city":
            return this.$t("Ort");

          case "message":
            return this.$t("Ihre Nachricht");
        }
      })(field);

      if (this.requiredFields.indexOf(field) > -1) {
        text += "*";
      }

      return text;
    },

    fieldValid(field) {
      if (!this.contact[field]) {
        return false;
      }

      if (field == "email") {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(this.contact[field]);
      }

      return true;
    },

    fieldError(field) {
      if (!this.busy) {
        return false;
      }

      if (this.requiredFields.indexOf(field) > -1) {
        if (!this.fieldValid(field)) {
          return true;
        }
      }

      if (field == "dataPrivacy" && !this.dataPrivacy) {
        return true;
      }

      return false;
    }
  }
};
</script>
